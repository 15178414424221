<script setup lang="ts">
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = defineProps<{
  onConfirm?: () => Promise<void>
  onCancel?: () => Promise<void>
}>()

const emit = defineEmits<{
  (event: 'hide'): void
  (event: 'loading', value: boolean): void
}>()

const { autopayModal } = useUser()
const { enableDirectDebit } = useCms()
const { translate } = useLocalization()

const isDontShowAgain = ref<boolean>(false)

const { loading, confirming, cancelling, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: async () => {
      autopayModal.value = isDontShowAgain.value
      await props.onConfirm?.()
      hide()
    },
    onCancel: async () => {
      autopayModal.value = isDontShowAgain.value
      await props.onCancel?.()
      hide()
    },
    hide,
    emit,
  })

function hide() {
  emit('hide')
}
</script>

<template>
  <div class="relative">
    <div class="absolute right-6 top-6">
      <base-button
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: `Enable ${translate('directDebit.text')}`,
          cta: 'Close',
        }"
        icon="heroicons/outline/x-mark"
        :size="32"
        :disabled="loading"
        @click="hide"
      />
    </div>
    <div class="p-5">
      <div class="flex flex-col items-center">
        <h2 class="w-72 text-center text-xl text-neutral-800">
          {{ enableDirectDebit.title }}
        </h2>
      </div>
    </div>
    <div>
      <div
        class="p-5 text-sm text-eonx-neutral-600"
        v-html="enableDirectDebit.content"
      ></div>
      <div class="border-t p-5">
        <base-checkbox
          v-model="isDontShowAgain"
          v-analytics:change="
            () => ({
              pageGroup: 'Make a payment',
              page: `Enable ${translate('directDebit.text')}`,
              label: `${
                isDontShowAgain ? 'Tick' : 'Untick'
              } Don't remind me again`,
            })
          "
          look="v4"
          class="mb-4"
        >
          <span class="font-normal text-eonx-neutral-800">
            Don’t remind me again
          </span>
        </base-checkbox>
        <div class="flex w-full gap-2">
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: `Enable ${translate('directDebit.text')}`,
              cta: 'Not now',
            }"
            class="flex-auto"
            :loading="cancelling"
            :disabled="loading"
            look="outlined-color"
            @click="onCancel"
          >
            Not now
          </base-button>
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: `Enable ${translate('directDebit.text')}`,
              cta: 'Let’s do it',
            }"
            class="flex-auto"
            :loading="confirming"
            :disabled="loading"
            @click="onConfirm"
          >
            Let’s do it
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
